import { combineReducers } from "redux";

import invoices from "./invoices";
import purchases from "./purchases";
import clients from "./clients";
import suppliers from "./suppliers";
import auth from "./auth";
import profiles from "./profiles";
import items from "./items";
import meetings from "./meetings";
import categories from "./categories";
import stocks from "./stocks";
import stockAdjustments from "./stockAdjustments";
import staff from "./staff";
import staffCommissionDetails from "./staffCommissionDetails";
import units from "./units";
export default combineReducers({
  invoices,
  purchases,
  clients,
  auth,
  profiles,
  items,
  meetings,
  suppliers,
  categories,
  stocks,
  stockAdjustments,
  staff,
  staffCommissionDetails,
  units
});
