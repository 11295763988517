import React, { useRef, useEffect } from "react";
import domToImage from "dom-to-image";
import { useDispatch, useSelector } from "react-redux";
import { updateInvoice } from "../../actions/invoiceActions";
import moment from "moment";
const InvoicePreview = ({ invoice ,isTaxCopy}) => {
  return (
    <div
      id="PrintContent"
      style={{
        width: "auto",
        padding: "20px 10px",
        lineHeight: "35px",
        backgroundColor: "white",
        border: "0px solid #FFFFFF",
        
      }}
    >
      <div
        style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}
      >
        {isTaxCopy?"Tax Copy":"Customer Copy"}
      </div>
      <div
        style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}
      >
        MindTree Technologies Co. Ltd.
      </div>
      {/* <div style={{ textAlign: "center", marginTop: "20px", fontSize: "28px" }}>
        REG Nn: 200501519R
        <br />
        GST No.: 20-0501519-R
      </div> */}

      <div style={{ fontSize: "22px", textAlign: "center", width: "100%" }}>
        PHONE: +65 6509 8638
      </div>
      <div
        style={{
          width: "100%",
          borderBottom: "medium dotted #333333",
          height: "5px",
        }}
      ></div>
      <div
        style={{
          marginTop: "30px",
          fontSize: "4rem",
          textAlign: "center",
          fontWeight: "400px",
          width: "100%",
        }}
      >
        ฿{invoice.total.toFixed(2) }
      </div>
      <div
        style={{
          marginTop: "10px",
          fontSize: "18px",
          textAlign: "center",
          fontWeight: "bold",
          width: "100%",
        }}
      >
        Total
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "28px",
          textAlign: "left",
          marginTop: "20px",
          width: "100%",
          borderTop: "medium dotted #333333",
          padding: "10px 0 10px 0",
        }}
      >
        <p>
          <strong>Bill To:</strong>{" "}
          {invoice?.client?.name ? invoice.client.name : "Cash"}
        </p>
        <p>
          <strong>Inv #:</strong> {invoice.invoiceNumber}
        </p>
      </div>
      <div
        style={{
          fontSize: "28px",
          textAlign: "left",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <span style={{ fontSize: "28px", width: "50%" }}>
          Date: {moment(invoice.createdAt).format("DD-MM-YYYY")}
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          Time: {moment(invoice.createdAt).format("hh:mm a")}
        </span>
      </div>
      <div
        style={{
          width: "100%",
          borderBottom: "medium dotted #333333",
          height: "5px",
        }}
      ></div>
      <div style={{maxHeight:"200px",overflowY:"auto"}}>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            fontSize: "24px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "75%", fontSize: "28px" }}>Items</th>
              <th
                style={{
                  width: "20px",
                  textAlign: "center",
                  fontSize: "28px",
                  right: "30%",
                }}
              >
                Qty
              </th>
              <th
                style={{ width: "50%", textAlign: "right", fontSize: "28px" }}
              >
                Amount
              </th>
            </tr>
          </thead>
        </table>

        <div
          style={{
            width: "100%",
            borderBottom: "medium dotted #333333",
            height: "5px",
          }}
        ></div>
        {invoice?.items?.map((itemField, index) => (
          <table key={index} style={{ width: "100%", fontSize: "24px" }}>
            <tbody>
              <tr>
                <td style={{ width: "75%" }}>{itemField?.item?.itemName}</td>
                <td
                  style={{
                    width: "20px",
                    textAlign: "center",

                    right: "30%",
                  }}
                >
                  {itemField?.quantity}
                </td>
                <td style={{ width: "50%", textAlign: "right" }}>
                  {itemField?.quantity * itemField.item?.units?.sale?.price}
                </td>
              </tr>
            </tbody>
          </table>
        ))}
        <div
          style={{
            width: "100%",
            borderBottom: "medium dotted #333333",
            height: "5px",
          }}
        ></div>
      </div>
      <div
        style={{
          fontSize: "28px",
          paddingTop: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: "28px", width: "62%" }}>Sub-Total </p>
        <p
          style={{
            fontSize: "24px",
          }}
        >
          {invoice.items.reduce((accumulator, item) => {
            return accumulator + parseInt(item.quantity, 10);
          }, 0)}
        </p>
        <p
          style={{
            fontSize: "28px",
          }}
        >
          ฿{invoice.subTotal}
        </p>
      </div>
      {/* <div style={{ fontSize: "28px", paddingTop: "10px", width: "100%" }}>
        <span style={{ fontSize: "28px", width: "60%" }}>Sub-total</span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "40%",
          }}
        >
          {invoice.total}
        </span>
      </div> */}
      {/* <div style={{ fontSize: "28px", paddingTop: "20px", width: "100%" }}>
        <span style={{ fontSize: "28px", width: "50%" }}>
          Service charge (10%)
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "40%",
          }}
        >
          S$6.10
        </span>
      </div> */}
      { invoice?.discount?  <div style={{ fontSize: "28px", paddingTop: "10px", width: "100%" }}>
        <span style={{ fontSize: "28px", width: "50%" }}> Discount</span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "40%",
          }}
        >
          ฿{(invoice.discount).toFixed(2)}
        </span>
      </div>:null}
     
      <div style={{ fontSize: "28px", paddingTop: "10px", width: "100%" }}>
        <span style={{ fontSize: "28px", width: "50%" }}> Tax</span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "40%",
          }}
        >
          ฿{(invoice.vat).toFixed(2)}
        </span>
      </div>
      
      <div
        style={{
          paddingTop: "20px",
          width: "100%",
          borderBottom: "medium dotted #333333",
          height: "5px",
        }}
      ></div>
      <div
        style={{
          fontSize: "28px",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <span style={{ fontSize: "28px", width: "50%", fontWeight: "bold" }}>
          Pay Amount(฿)
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          <strong>฿{(invoice.total).toFixed(2)}</strong>
        </span>
      </div>
      <div
        style={{
          fontSize: "28px",

          width: "100%",
        }}
      >
        <span style={{ fontSize: "24px", width: "50%", fontWeight: "bold" }}>
          Pay Mode
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          <strong>{invoice.paymentMode}</strong>
        </span>
      </div>
      {invoice.paymentMode === "Cash" &&<>
      <div
        style={{
          fontSize: "28px",

          width: "100%",
        }}
      >
        <span style={{ fontSize: "24px", width: "50%"}}>
          Received Amount
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          ฿{invoice.receivedAmount}
        </span>
      </div>
      <div
        style={{
          fontSize: "28px",

          width: "100%",
        }}
      >
        <span style={{ fontSize: "24px", width: "50%"}}>
          Change
        </span>
        
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          ฿{invoice.change}
        </span>
        
      </div>
      </>}
      
      <div
        style={{
          paddingTop: "20px",
          width: "100%",
          borderBottom: "medium dotted #333333",
          height: "5px",
        }}
      ></div>
      <div
        style={{
          fontSize: "28px",
          paddingTop: "20px",
          width: "100%",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        THANK YOU
      </div>
    </div>
  );
};

export default InvoicePreview;
