import React, { useState, useEffect, useContext, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddItem from "../components/Items/addItem";

import { StaffCommissionDetailContext } from "../Context/StaffCommissionDetailsContext";
import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  IconButton,
  Grid,
  CardMedia,
  InputBase,
  Badge,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableHeader,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { getAllStaff, getStaffByUser } from "../actions/staffActions";
import { Autocomplete } from "@material-ui/lab";
import { ItemContext } from "../Context/ItemContext";
import NoData from "../components/svgIcons/NoData";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch, useSelector } from "react-redux";
import { useCart } from "../Context/CartContext";
import { useDiscount } from "../Context/DiscountContext";
import { useCurrency } from "../Context/CurrencyContext";
import { getAllItems } from "../actions/itemActions";
import AddIcon from "@material-ui/icons/Add";
import CommissionDialog from "./CommissionDialog";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },

  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },

  itemCard: {
    flex: 1, // Allow the card to expand to fill the available space
    position: "relative",
    marginBottom: theme.spacing(2),
    backgroundColor: "#f5f5f5",
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: "10px",
  },

  cardContent: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "#fff",
    display: "flex",
    flexDirection: "column", // Display content in a column layout
    alignItems: "center",
    height: "50%",
    // Take half of the card's height
  },
  itemName: {
    fontSize: "0.8rem", // Default font size for item name
    marginBottom: theme.spacing(1),
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));
const options = {
  position: "bottom",
  style: {
    backgroundColor: "red",
    border: "2px solid red",
    color: "white",
    fontFamily: "Menlo, monospace",
    fontSize: "20px",
    textAlign: "center",
  },
  closeStyle: {
    color: "lightcoral",
    fontSize: "16px",
  },
};
const drawerWidth = 350;

const ItemCards = ({staffCommissionDetails}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    cartItems,
    handleAddToCart,
    setCartItems,
    searchQuery,
    setSearchQuery,
    selectedCategory,
  } = useCart();
  const { staffCommissionDetailsArray, setStaffCommissionDetailsArray } =
  useContext(StaffCommissionDetailContext);
  const { itemId, setItemId } = useContext(ItemContext);
  const { clearDiscount } = useDiscount();
  const items = useSelector((state) => state.items.items);
  //console.log(items);
  const staff = useSelector((state) => state.staff.staff);
  // console.log(
  //   staff.filter((staff) =>
  //     staff.services.some((service) => service.id === "658ea97fcba8db44101c2ba6")
  //   )
  // );
  const { currency, updateCurrency } = useCurrency();
  const [imageSources, setImageSources] = useState({});
  const [open, setOpen] = useState(true);
  const [openItem, setOpenItem] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar(options);
  const [openCommissionDialog, setOpenCommissionDialog] = useState({});
  const [selectedService, setSelectedService] = useState({});

  const [quantity, setQuantity] = useState(1);

  const filteredItems = items.filter((item) =>
    item.itemName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredItemsByCategory = selectedCategory
    ? filteredItems.filter(
        (item) => item.category.categoryId === selectedCategory._id
      )
    : filteredItems;
  useEffect(() => {
    dispatch(getAllItems());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllStaff());
    // dispatch(getStaffByUser({ search: user?.result?._id || user.result.googleId }));
  }, [dispatch]);
useEffect(()=>{
  if(staffCommissionDetails){
    setStaffCommissionDetailsArray(staffCommissionDetails)
  }
},[staffCommissionDetails])
// useEffect(() => {
//   const getCurrentStockQty = (itemId) => async (dispatch) => {

//     try {
//       const { data } = await api.getCurrentStockQuantity(id);
  
    
//     } catch (error) {
//       console.log(error.response);
//     }
//   };
//   getCurrentStockQty();

// },[cartItems]); 

  
  


  let barcodeScan = "";
  useEffect(() => {
    function handleKeyDown(e) {
      // console.log("handle")
      if (e.keyCode === 13 && barcodeScan.length > 3) {
        console.log("Barcode Scan: " + barcodeScan);
        handleBarcodeScan(barcodeScan);
        return;
      }
      if (e.keyCode === 16) {
        return;
      }
      barcodeScan += e.key;
      setTimeout(() => {
        barcodeScan = "";
        console.log("Barcode Scan: " + barcodeScan);
      }, 100);
    }
    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });
  const handleBarcodeScan = (scannedValue) => {
    console.log("handleBarcodeScan Called");
    const scannedItem = scannedValue;
    // Find the item with a matching barcode
    const matchingItem = items.find(
      (item) => item.barcode === scannedItem || item._id === scannedItem
    );
    console.log("Matching", matchingItem);
    if (matchingItem) {
      handleAddToCart(matchingItem, openSnackbar);
    } else {
      {
        openSnackbar("Item not found for the scanned barcode. Please check.");
      }
    }
  };
  if (filteredItemsByCategory.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <AddItem
          open={openItem}
          setOpen={setOpenItem}
          itemId={itemId}
          setItemId={setItemId}
        />
        <Card
          className={classes.itemCard}
          onClick={() => {
            setItemId(null);
            setOpenItem(true);
          }}
        >
          <CardMedia
            component="img"
            alt={"Add"}
            height="140"
            image="/images/add-button.svg"
            style={{ objectFit: "fill" }}
          />
        </Card>
        <p style={{ padding: "40px", color: "gray", textAlign: "center" }}>
          No Items in the selected Category yet
        </p>
      </div>
    );
  }

  const handleCommissionDialogOpen = (serviceId,service) => {
    setOpenCommissionDialog(prevState => ({
      ...prevState,
      [serviceId]: true
    }));
    setSelectedService(service);
    setQuantity(1);
  };
  const handleCommissionDialogClose = (serviceId) => {
    setOpenCommissionDialog(prevState => ({
      ...prevState,
      [serviceId]: false
    }));
  };

  return (
    <>
      <AddItem
        open={openItem}
        setOpen={setOpenItem}
        itemId={itemId}
        setItemId={setItemId}
      />
      <main
        className={`${classes.content} ${open ? classes.contentShift : ""}`}
        style={{ marginLeft: 0 }}
      >
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <ButtonBase centerRipple={true}>
              <Card
                className={classes.itemCard}
                onClick={() => {
                  setItemId(null);
                  setOpenItem(true);
                }}
              >
                <CardMedia
                  component="img"
                  alt={"Add"}
                  height="140"
                  image="/images/add-button.svg"
                  style={{
                    objectFit: "contain",
                    height: "100px",
                    width: "80px",
                    margin: "20px",
                  }}
                />
              </Card>
            </ButtonBase>
          </Grid>
          {filteredItemsByCategory.map((item) => (
            <>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={item._id}>
                <ButtonBase centerRipple={true}>
                  <Card
                    className={classes.itemCard}
                    onClick={() => {
                      if (item.isStaffReq) {
                        handleCommissionDialogOpen(item._id,item);
                      } else {
                        
                        handleAddToCart(item, openSnackbar);
                      }
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt={item.itemName}
                      height="140"
                      image={item.img ? item.img : "/images/Empty.jfif"}
                      style={{ objectFit: "fill" }}
                    />
                    <CardContent className={classes.cardContent}>
                      <div>
                        <Typography variant="body1">
                          {currency}
                          {item?.units?.sale?.price}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          variant="h6"
                          className={classes.itemName}
                          gutterBottom
                        >
                          {item.itemName}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </ButtonBase>
              </Grid>
              <CommissionDialog
              open={openCommissionDialog[item._id] || false}
              staff={staff}
              staffCommissionDetailEdit={staffCommissionDetails.find((scd)=>scd.serviceId ===item._id)}
              onClose={() => handleCommissionDialogClose(item._id)}
                serviceId={selectedService._id}
                service={selectedService}
                openSnackbar={openSnackbar}
              />
            </>
          ))}
        </Grid>
      </main>
    </>
  );
};

export default ItemCards;
