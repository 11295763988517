import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [showForm, setShowForm] = useState(false);
  const [stockAdjustmentShowForm, setStockAdjustmentShowForm] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  return (
    <AppContext.Provider
      value={{
        showForm,
        setShowForm,
        stockAdjustmentShowForm,
        setStockAdjustmentShowForm,
        openPaymentModal,
        setOpenPaymentModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
