import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../Context/AppContext.js";
import { EditContext } from "../Context/EditContext.js";
import { format } from "../intl.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  Container,
  Card,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { getInvoicesByClient } from "../actions/invoiceActions";
import NoData from "../components/svgIcons/NoData";
import InvoicesPayment from "./InvoicesPayment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(2),
  },
  container: {
    maxWidth: "1200px", // Adjust as needed
    width: "100%",
    padding: theme.spacing(0, 2), // Add horizontal padding
  },
  tableStyle: {
    width: 160,
    fontSize: 14,
    cursor: "pointer",
    borderBottom: "none",
    padding: "8px",
    textAlign: "center",
  },
  headerStyle: {
    borderBottom: "none",
    textAlign: "center",
  },
}));

const tableStyle = {
  width: 160,
  fontSize: 14,
  cursor: "pointer",
  borderBottom: "none",
  padding: "8px",
  textAlign: "center",
};

const headerStyle = { borderBottom: "none", textAlign: "center" };

const Stats = () => {
  const classes = useStyles();
  const { showForm, setShowForm } = useContext(AppContext);
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const { currId, setCurrId, customerId, setCustomerId } =
    useContext(EditContext);
  const history = useHistory();
  const { clientId } = useParams();
  console.log("ClientId", clientId);
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);

  const { invoices } = useSelector((state) => state.invoices);
  console.log("Invoices", invoices);
  const user = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    if (clientId) {
      dispatch(getInvoicesByClient(clientId));
    }
  }, [dispatch, clientId]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const dueInvoices = invoices.filter(
    (invoice) =>
      invoice.status !== "Paid" &&
      (invoice.totalAmountReceived || 0) < invoice.total
  );

  const paidInvoices = invoices.filter(
    (invoice) =>
      invoice.status === "Paid" || invoice.totalAmountReceived >= invoice.total
  );
  useEffect(() => {
    const totalDue = invoices.reduce((total, invoice) => {
      return (
        total +
        (Number(invoice.total) - Number(invoice.totalAmountReceived || 0))
      );
    }, 0);
    setTotalDueAmount(totalDue);
  }, [invoices]);
  const handleWriteOff = () => {
    setCustomerId(invoices[0].client);
    setShowForm(true);

    // setOpenPaymentModal(true);
  };
  function checkStatus(status) {
    return status === "Partial"
      ? {
          border: "solid 0px #1976d2",
          backgroundColor: "#baddff",
          padding: "8px 18px",
          borderRadius: "20px",
        }
      : status === "Paid"
      ? {
          border: "solid 0px green",
          backgroundColor: "#a5ffcd",
          padding: "8px 18px",
          borderRadius: "20px",
        }
      : status === "Due"
      ? {
          border: "solid 0px red",
          backgroundColor: "#ffaa91",
          padding: "8px 18px",
          borderRadius: "20px",
        }
      : status === "" || status === undefined || status === null
      ? {
          border: "solid 0px red",
          backgroundColor: "#ffaa91",
          padding: "8px 18px",
          borderRadius: "20px",
        }
      : "red";
  }
  const renderInvoiceTable = (invoicesToRender) =>
    invoicesToRender.length > 0 ? (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={headerStyle}>Date</TableCell>
              <TableCell style={headerStyle}>Invoice Number</TableCell>
              <TableCell style={headerStyle}>Customer</TableCell>
              <TableCell style={headerStyle}>Invoice Amt</TableCell>
              <TableCell style={headerStyle}>Recieved Amt</TableCell>
              <TableCell style={headerStyle}>Due Amt</TableCell>
              <TableCell style={headerStyle}>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoicesToRender.map((invoice) => (
              <TableRow key={invoice._id}>
                <TableCell style={tableStyle}>
                  {moment(invoice.date).format("Do MMM YYYY")}
                </TableCell>
                <TableCell style={tableStyle}>
                  {invoice.invoiceNumber}
                </TableCell>
                <TableCell style={tableStyle}>{invoice.client?.name}</TableCell>
                <TableCell style={tableStyle}>
                  {format(invoice.total || 0)}
                </TableCell>

                <TableCell style={tableStyle}>
                  {format(invoice.totalAmountReceived || 0)}
                </TableCell>
                <TableCell style={tableStyle}>
                  {format(
                    invoice.total - (invoice.totalAmountReceived || 0) || 0
                  )}
                </TableCell>

                <TableCell style={tableStyle}>
                  <button style={checkStatus(invoice.status)}>
                    {invoice.status}
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <NoData />
        <p style={{ color: "gray", textAlign: "center" }}>No Invoices Found</p>
      </div>
    );
  const renderPaymentsTable = (invoicesToRender) =>
    invoicesToRender.length > 0 ? (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={headerStyle}>Invoice Date</TableCell>
              <TableCell style={headerStyle}>Payment Date</TableCell>
              <TableCell style={headerStyle}>Invoice Number</TableCell>
              <TableCell style={headerStyle}>Customer</TableCell>
              <TableCell style={headerStyle}>Total Amt</TableCell>
              <TableCell style={headerStyle}>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoicesToRender.map((invoice) => (
              <TableRow key={invoice._id}>
                <TableCell style={tableStyle}>
                  {moment(invoice.date).format("Do MMM YYYY")}
                </TableCell>
                <TableCell style={tableStyle}>
                  {invoice.paymentRecords && invoice.paymentRecords.length > 0
                    ? moment(
                        invoice.paymentRecords[
                          invoice.paymentRecords.length - 1
                        ].date
                      ).format("Do MMM YYYY")
                    : "No Payment Records"}
                </TableCell>
                <TableCell style={tableStyle}>
                  {invoice.invoiceNumber}
                </TableCell>
                <TableCell style={tableStyle}>{invoice.client?.name}</TableCell>

                <TableCell style={tableStyle}>
                  {format(invoice.total)}
                </TableCell>

                <TableCell style={tableStyle}>
                  <button style={checkStatus(invoice.status)}>
                    {invoice.status}
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <NoData />
        <p style={{ color: "gray", textAlign: "center" }}>No Invoices Found</p>
      </div>
    );
  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h4">
          Due Invoices -{" "}
          {invoices && invoices.length > 0 && invoices[0]?.client
            ? invoices[0].client.name
            : "Client"}
        </Typography>

        <Card className="p-4 mb-4">
          <CardContent>
            <Typography variant="h6" className="mb-3">
              Total Due Amount: ${totalDueAmount.toFixed(2)}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleWriteOff}
              className="mt-2"
            >
              Add Payment
            </Button>
          </CardContent>
        </Card>

        <Grid item xs={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Due Invoices" />
              <Tab label="Paid Invoices" />
            </Tabs>
          </AppBar>

          <Box p={3}>
            {currentTab === 0 && renderInvoiceTable(dueInvoices)}
            {currentTab === 1 && renderPaymentsTable(paidInvoices)}

            {/* <InvoicesPayment
              open={openPaymentModal}
              setOpen={setOpenPaymentModal}
              invoices={dueInvoices}
            /> */}
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default Stats;
