import * as api from '../api';
import { 
  FETCH_UNITS, 
  ADD_NEW_UNIT, 
  ADD_NEW_UNITS, 
  START_LOADING, 
  END_LOADING,
  UPDATE_UNIT,
  DELETE_UNIT

}  from './constants'

export const getAllUnits = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchUnits();
    dispatch({ type: FETCH_UNITS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const createUnit = (unit, openSnackbar) => async (dispatch) => {
  try {
    const { data } = await api.addUnit(unit);
    dispatch({ type: ADD_NEW_UNIT, payload: data });
    openSnackbar("Unit added successfully");
  } catch (error) {
    console.log(error);
  }
};

export const createUnits = (units, openSnackbar) => async (dispatch) => {
  try {
    const { data } = await api.addUnits(units);
    dispatch({ type: ADD_NEW_UNITS, payload: data });
    openSnackbar("Units added successfully");
  } catch (error) {
    console.log(error);
  }
};

export const updateUnit = (id, unit, openSnackbar) => async (dispatch) => {
  try {
    const { data } = await api.updateUnit(id, unit);
    dispatch({ type: UPDATE_UNIT, payload: data });
    openSnackbar("Unit updated successfully");
  } catch (error) {
    console.log(error);
  }
};

export const deleteUnit = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteUnit(id);
    dispatch({ type: DELETE_UNIT, payload: id });
    openSnackbar("Unit deleted successfully");
  } catch (error) {
    console.log(error);
  }
};

export const convertUnits = (fromUnitId, toUnitId, quantity) => async (dispatch) => {
  try {
    const { data } = await api.convertUnits({
      fromUnitId, 
      toUnitId, 
      quantity
    });
    return data.convertedQuantity;
  } catch (error) {
    console.log(error);
  }
};