import { formatMs } from "@material-ui/core";
import {
    FETCH_UNITS,
    ADD_NEW_UNIT,
    ADD_NEW_UNITS,
    START_LOADING,
    END_LOADING,
    UPDATE_UNIT,
    DELETE_UNIT
} from "../actions/constants";
  const dummyUnits = [
    {
      _id: '661a1f3b4b3f5a001f3e1001',
      name: 'Kilogram',
      abbreviation: 'kg',
      baseUnit: null,
      conversionFactor: 1,
      type: ['STOCK', 'SALE', 'PURCHASE', 'ADJUSTMENT']
    },
    {
      _id: '661a1f3b4b3f5a001f3e1002',
      name: 'Gram',
      abbreviation: 'g',
      baseUnit: '661a1f3b4b3f5a001f3e1001', // Reference to Kilogram
      conversionFactor: 0.001,
      type: ['STOCK', 'SALE', 'PURCHASE']
    },
    {
      _id: '661a1f3b4b3f5a001f3e1003',
      name: 'Liter',
      abbreviation: 'L',
      baseUnit: null,
      conversionFactor: 1,
      type: ['STOCK', 'SALE', 'PURCHASE', 'ADJUSTMENT']
    },
    {
      _id: '661a1f3b4b3f5a001f3e1004',
      name: 'Milliliter',
      abbreviation: 'mL',
      baseUnit: '661a1f3b4b3f5a001f3e1003', // Reference to Liter
      conversionFactor: 0.001,
      type: ['STOCK', 'SALE', 'PURCHASE']
    }
  ];
  
   // Import the dummy units
  
  const initialState = {
    isLoading: false, // Set to false since we have initial data
    units: [], // Use dummy units as initial state
  };
  
  const unitsReducer = (state = initialState, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      
      case END_LOADING:
        return { ...state, isLoading: false };
      
      case FETCH_UNITS:
        return {
          ...state,
          units: action.payload,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      
      case ADD_NEW_UNIT:
        return { ...state, units: [...state.units, action.payload] };
      
      case ADD_NEW_UNITS:
        return { ...state, units: [...state.units, ...action.payload] };
      
      case UPDATE_UNIT:
        return {
          ...state,
          units: state.units.map((unit) =>
            unit._id === action.payload._id ? action.payload : unit
          ),
        };
      
      case DELETE_UNIT:
        return {
          ...state,
          units: state.units.filter((unit) => unit._id !== action.payload),
        };
      
      default:
        return state;
    }
  };
  
  export default unitsReducer;