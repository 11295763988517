import React, { createContext, useState } from "react";
import * as api from "../api/index";
// Create the StockContext
const StockContext = createContext();

// Create a StockProvider component
const StockProvider = ({ children }) => {
  // Define the state for the stock array
  const [trackStock, setTrackStock] = useState(false);
  const [stock, setStock] = useState([]);
  const getCurrentStockQty = async (itemId) => {
    try {
      debugger
      const { data } = await api.getCurrentStockQuantity(itemId);
//console.log(data);
      return data;
    } catch (error) {
      console.log(error.response);
    }
  };
  const getConvertedQuantity = async (item, type = 'sale') => {
    try {
      debugger;
      const unitData = {
        fromUnitId: item?.units?.[type]?.unit?._id,
        quantity: item.quantity,
      };
      
      const { data } = await api.convertUnits(unitData);
      console.log(data);
      
      // Multiply by -1 for sales, keep as is for purchases
      return data.convertedQuantity * (type === 'sale' ? -1 : 1);
    } catch (error) {
      console.log(error.response);
    }
  };
  // You can define additional functions to update the stock array if needed
  // Provide the state and any additional functions through the context
  const contextValue = {
    stock,
    trackStock,
    setTrackStock,
    getCurrentStockQty,
    getConvertedQuantity
    // Add any additional functions here
  };

  return (
    <StockContext.Provider value={contextValue}>
      {children}
    </StockContext.Provider>
  );
};

export { StockContext, StockProvider };
