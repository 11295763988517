import React, { useState, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import Barcode from "react-barcode";
import BarcodePrintComponent from "./BarcodePrintComponent";
import ReactToPrint from "react-to-print";
import AllBarcodesPrint from "./AllBarcodesPrint";
import { ItemContext } from "../../Context/ItemContext";
import styles from "./Items.module.css";
import PropTypes from "prop-types";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import {
  Print as PrintIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from "@material-ui/icons";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Container from "@material-ui/core/Container";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import {
  Button,
  Dialog,
  DialogContent,
  InputBase,
  Typography,
  Box,
} from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { useMediaQuery } from "@material-ui/core";
import { deleteItem } from "../../actions/itemActions";
import CloseIcon from "@material-ui/icons/Close";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
    
  },

  tablecell: {
    fontSize: "16px",
    
  },
  circularTextField: {
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    border: "1px solid #ccc",
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
  },
  inputBase: {
    flex: 1,
    textAlign: "center",
    padding: 0,
    fontWeight: "bold",
    fontSize: "20px",
  },
  dialogRoot: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#1976D2",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
  addCategoryButton: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    alignContent: "end",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles2();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
const Items = ({ setOpen, items }) => {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { itemId, setItemId } = useContext(ItemContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set default rows per page
  const [barcodePrintdialogOpen, setBarcodePrintDialogOpen] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState("");
  const compRef = useRef(null);
  const [barcodeData, setBarcodeData] = useState({
    itemName: "",
    barcode: "",
    itemId: "",
  });
  const [barcodeLabelPrintQuantity, setBarcodeLabelPrintQuantity] = useState(1);
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const dispatch = useDispatch();
  const rows = items;
  const filteredRows = items.filter((row) =>
    row.itemName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const componentRef = useRef();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (selectedInvoice) => {
    //(selectedInvoice);
    setOpen((prevState) => !prevState);
    setItemId(selectedInvoice);
  };
  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmDelete) {
      dispatch(deleteItem(id, openSnackbar));
    }
  };
  const handlePrintBarcodeDialogOpen = (rowId, Name, barcode, index) => {
    const updatedBarcodeData = {
      itemName: Name,
      itemId: rowId,
      barcode: barcode,
    };
    setBarcodeData(updatedBarcodeData);
    setBarcodeLabelPrintQuantity(1);
    setBarcodePrintDialogOpen(true);
  };

  const handlePrintBarcodeDialogClose = () => {
    setBarcodePrintDialogOpen(false);
  };
  const handleLabelPrintQuantityChange = (event) => {
    setBarcodeLabelPrintQuantity(parseInt(event.target.value, 10));
  };
  const incrementLabelPrintQuantity = () => {
    setBarcodeLabelPrintQuantity(barcodeLabelPrintQuantity + 1);
  };

  const decrementLabelPrintQuantity = () => {
    if (barcodeLabelPrintQuantity > 1) {
      setBarcodeLabelPrintQuantity(barcodeLabelPrintQuantity - 1);
    }
  };
  const handleLabelPrint = () => {
    // Handle printing logic here, e.g., send barcodeValue and quantity to the printer
    // You can use a library like 'react-to-print' or implement your own print functionality
  };
  const tableStyle = {
    width: 160,
    fontSize: 14,
    cursor: "pointer",
    borderBottom: "none",
    padding: "8px",
    textAlign: "center",
  };
  const headerStyle = { borderBottom: "none", textAlign: "center" };

  return (
    <div className={styles.pageLayout}>
      <Container style={{ width: isMobile ? "100%" : "85%" }}>
        <Box className={classes.inputContainer}>
          <TextField
            id="search-bar"
            label="Search"
            variant="outlined"
            margin="normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
       
       
           
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" style={{margin:"5px",marginTop:"1.5rem"}} startIcon={<PrintIcon/>}>
                  Print Barcodes
                </Button>
              )}
              content={() => compRef.current}
            />
           <div style={{ backgroundColor: "lightGrey",display:"none" }}>
              <AllBarcodesPrint items={items} ref={compRef} />
            </div>
        </Box>
        <TableContainer component={Paper} elevation={0}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell style={{ ...headerStyle, width: "10px" }}>
                  Number
                </TableCell>
                <TableCell style={headerStyle}>Name</TableCell>
                <TableCell style={headerStyle}>MRP</TableCell>
                <TableCell style={headerStyle}>Sales Unit</TableCell>
                <TableCell style={headerStyle}>Purchase Unit</TableCell>
                <TableCell style={headerStyle}>Stock Unit</TableCell>
                <TableCell style={headerStyle}>Category</TableCell>
                <TableCell style={headerStyle}>Barcode</TableCell>
                <TableCell style={headerStyle}>Edit</TableCell>
                <TableCell style={headerStyle}>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows
              ).map((row, index) => (
                <TableRow key={row._id} styel={{ cursor: "pointer" }}>
                  <TableCell style={{ ...tableStyle, width: "10px" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell style={tableStyle} scope="row">
                    <Button style={{ textTransform: "none" }}>
                      {row.itemName}
                    </Button>
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {row.units?.sale && row.units?.sale?.price
                      ? `฿${ row.units?.sale?.price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : "N/A"}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {row.units?.sale?.unit ? row.units.sale.unit.name  : ""}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {row.units?.purchase?.unit ? row.units.purchase.unit.name  : ""}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {row.units?.stock?.unit ? row.units.stock.unit.name  : ""}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {row.category ? row.category.categoryName : ""}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    <IconButton
                      onClick={() =>
                        handlePrintBarcodeDialogOpen(
                          row._id,
                          row.itemName,
                          row.barcode,
                          index
                        )
                      }
                    >
                      <PrintIcon />
                    </IconButton>
                    <Dialog
                      open={barcodePrintdialogOpen}
                      // onClose={handlePrintBarcodeDialogClose}
                      onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                          handlePrintBarcodeDialogClose();
                        }
                      }}
                    >
                      <DialogTitle
                        id="customized-dialog-title"
                        onClose={handlePrintBarcodeDialogClose}
                        style={{
                          paddingLeft: "20px",
                          color: "white",
                          backgroundColor: "#ef7f1a",
                        }}
                      >
                        Print Label
                      </DialogTitle>

                      <DialogContent>
                        <div>
                          <p>{barcodeData.itemName}</p>
                          <Barcode
                            value={
                              barcodeData.barcode
                                ? barcodeData.barcode
                                : barcodeData.itemId
                            }
                            width={1}
                            height={30}
                            displayValue={true}
                          />
                          <div
                            style={{ backgroundColor: "blue", display: "none" }}
                          >
                            <BarcodePrintComponent
                              barcodeData={barcodeData}
                              quantity={barcodeLabelPrintQuantity}
                              ref={componentRef}
                            />
                          </div>
                        </div>
                        <div className={classes.circularTextField}>
                          <IconButton onClick={decrementLabelPrintQuantity}>
                            <RemoveIcon />
                          </IconButton>

                          <InputBase
                            classes={{
                              input: classes.inputBase,
                            }}
                            type="number"
                            value={barcodeLabelPrintQuantity}
                            onChange={handleLabelPrintQuantityChange}
                          />
                          <IconButton onClick={incrementLabelPrintQuantity}>
                            <AddIcon />
                          </IconButton>
                        </div>

                        <ReactToPrint
                          trigger={() => (
                            <Button variant="contained" color="primary">
                              Print
                            </Button>
                          )}
                          content={() => componentRef.current}
                        />
                      </DialogContent>
                    </Dialog>
                  </TableCell>
                  <TableCell style={{ ...tableStyle, width: "10px" }}>
                    <IconButton onClick={() => handleEdit(row._id)}>
                      <BorderColorIcon
                        style={{ width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ ...tableStyle, width: "10px" }}>
                    <IconButton onClick={() => handleDelete(row._id)}>
                      <DeleteOutlineRoundedIcon
                        style={{ width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={filteredRows.length} // Update count to the number of filtered rows
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default Items;
