import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { ItemContext } from "../../../Context/ItemContext";
import styles from "./units.module.css";
import PropTypes from "prop-types";
import { makeStyles , useTheme} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Container from "@material-ui/core/Container";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { Button } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { useMediaQuery } from "@material-ui/core";
import { deleteUnit } from "../../../actions/unitActions";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },

  tablecell: {
    fontSize: "16px",
  },
}));

const Units = ({ setOpen, units }) => {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { unitId,setUnitId } = useContext(ItemContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25); // Set default rows per page

  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const dispatch = useDispatch();
  const rows = units;
  // const filteredRows = units.filter((row) =>
  //   row.itemName.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  const filteredRows = rows.filter((row) => {
    const searchWords = searchQuery.toLowerCase().split(" ").filter(Boolean); // Split the search query into words
    const combinedWord= row.itemName + (row.weight?row.weight:"");
    return searchWords.every(word =>
      combinedWord.toLowerCase().includes(word)
    );
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (selectedInvoice) => {
    //(selectedInvoice);
    setOpen((prevState) => !prevState);
    setUnitId(selectedInvoice);
  };
  const handleDelete= (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this unit?');
  if(confirmDelete){
    dispatch(deleteUnit(id, openSnackbar))
  }
  }
  const tableStyle = {
    width: 160,
    fontSize: 14,
    cursor: "pointer",
    borderBottom: "none",
    padding: "8px",
    textAlign: "center",
  };
  const headerStyle = { borderBottom: "none", textAlign: "center" };

  return (
    <div className={styles.pageLayout}>
      <Container style={{ width: isMobile ? "100%":"85%"}}>
        <TextField
          id="search-bar"
          label="Search"
          variant="outlined"
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <TableContainer component={Paper} elevation={0}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell style={{ ...headerStyle, width: "10px" }}>
                  Number
                </TableCell>
                <TableCell style={headerStyle}>Name</TableCell>
                <TableCell style={headerStyle}>Abbreviation</TableCell>
                <TableCell style={headerStyle}>Conversion Factor</TableCell>
                <TableCell style={headerStyle}>Edit</TableCell>
                <TableCell style={headerStyle}>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows
              ).map((row, index) => (
                <TableRow key={row._id} styel={{ cursor: "pointer" }}>
                  <TableCell style={{ ...tableStyle, width: "10px" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell style={tableStyle} scope="row">
                    <Button style={{ textTransform: "none" }}>
                      {row.name}
                    </Button>
                  </TableCell>
                  {/* <TableCell style={tableStyle}>{row.unitPrice && row.unitPrice
                      ? `₹${row.unitPrice
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : "N/A"}</TableCell> */}
                  <TableCell style={tableStyle}>{row.abbreviation}</TableCell>
                  <TableCell style={tableStyle}>{row.conversionFactor}</TableCell>
                  <TableCell style={{ ...tableStyle, width: "10px" }}>
                    <IconButton onClick={() => handleEdit(row._id)}>
                      <BorderColorIcon
                        style={{ width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ ...tableStyle, width: "10px" }}>
                    <IconButton
                      onClick={() =>
                        handleDelete(row._id)}
                      
                    >
                      <DeleteOutlineRoundedIcon
                        style={{ width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={filteredRows.length} // Update count to the number of filtered rows
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default Units;
