import axios from "axios";

// const API = axios.create({ baseURL: 'http://localhost:5000'})
const API = axios.create({ baseURL: process.env.REACT_APP_API });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  return req;
});

// export const fetchInvoices =() => API.get('/invoices')
export const fetchInvoice = (id) => API.get(`/invoices/${id}`);
export const fetchInvoicesByClient = ({
  page,
  rowsPerPage,
  id,
  startDate,
  endDate,
}) =>
  API.post(`/invoices/client`, { page, rowsPerPage, id, startDate, endDate });
export const addInvoice = (invoice) => API.post("/invoices", invoice);
export const updateInvoice = (id, updatedInvoice) =>
  API.post(`/invoices/${id}`, updatedInvoice);
export const deleteInvoice = (id) => API.post(`/invoices/delete/${id}`);
export const fetchInvoicesByUser = (searchQuery) =>
  API.get(`/invoices/user?searchQuery=${searchQuery.search}`);
export const fetchInvoices = () => API.get("/invoices");
export const updateStaffCommissionDetail = (id, updatedStaffCommissionDetail) =>
  API.post(`/staffCommissionDetails/update/${id}`, updatedStaffCommissionDetail);
export const fetchStaffCommissionDetail = (id) =>
  API.get(`/staffCommissionDetails/${id}`);
export const addStaffCommissionDetail = (staffCommissionDetail) =>
  API.post("/staffCommissionDetails", staffCommissionDetail);


export const updateStaffCommissionDetails = async (
  updatedStaffCommissionDetails
) => {
  try {
    console.log(
      "Update StaffCommissionDetails from API index: ",
      updatedStaffCommissionDetails
    );
    const response = await API.post(
      `/staffCommissionDetails/update`,
      updatedStaffCommissionDetails
    );

    if (response.status === 200) {
      console.log("staffCommissionDetails updated successfully");
    } else {
      console.error("Failed to update staffCommissionDetails");
    }
  } catch (error) {
    console.error(
      "An error occurred while updating staffCommissionDetails:",
      error
    );
  }
};
export const deleteStaffCommissionDetail = (id) =>
  API.post(`/staffCommissionDetails/delete/${id}`);
export const fetchStaffCommissionDetailsByUser = (searchQuery) =>
  API.get(`/staffCommissionDetails/user?searchQuery=${searchQuery.search}`);
export const fetchStaffCommissionDetails = () =>
  API.get("/staffCommissionDetails");

export const fetchPurchase = (id) => API.get(`/purchases/${id}`);
export const addPurchase = (purchase) => API.post("/purchases", purchase);
export const updatePurchase = (id, updatedPurchase) =>
  API.post(`/purchases/${id}`, updatedPurchase);
export const deletePurchase = (id) => API.post(`/purchases/delete/${id}`);
export const fetchPurchasesByUser = (searchQuery) =>
  API.get(`/purchases/user?searchQuery=${searchQuery.search}`);
export const fetchPurchases = () => API.get("/purchases");

export const fetchMeeting = (id) => API.get(`/meetings/${id}`);
export const addMeeting = (meeting) => API.post("/meetings", meeting);
export const updateMeeting = (id, updatedMeeting) =>
  API.post(`/meetings/${id}`, updatedMeeting);
export const deleteMeeting = (id) => API.post(`/meetings/delete/${id}`);
export const fetchMeetingsByUser = (searchQuery) =>
  API.get(`/meetings?searchQuery=${searchQuery.search}`);
export const fetchMeetings = () => API.get("/meetings");

export const fetchClient = (id) => API.get(`/clients/${id}`);
export const fetchClients = (page) => API.get(`/clients?page=${page}`);
export const addClient = (client) => API.post("/clients", client);
export const updateClient = (id, updatedClient) =>
  API.post(`/clients/${id}`, updatedClient);
export const deleteClient = (id) => API.post(`/clients/delete/${id}`);
export const fetchClientsByUser = (searchQuery) =>
  API.get(`/clients/user?searchQuery=${searchQuery.search}`);
export const fetchAllClients = () => API.get("/clients");

export const fetchSupplier = (id) => API.get(`/staff/${id}`);
export const fetchSuppliers = (page) => API.get(`/staff?page=${page}`);
export const addSupplier = (client) => API.post("/staff", client);
export const updateSupplier = (id, updatedSupplier) =>
  API.post(`/staff/${id}`, updatedSupplier);
export const deleteSupplier = (id) => API.post(`/staff/delete/${id}`);
export const fetchSuppliersByUser = (searchQuery) =>
  API.get(`/staff/user?searchQuery=${searchQuery.search}`);
export const fetchAllSuppliers = () => API.get("/staff");

// export const adminSignIn =(formData)=> API.post('/users/adminSignin', formData)
export const adminSignUp = (formData) =>
  API.post("/users/adminSignup", formData);
export const signIn = (formData) => API.post("/users/signin", formData);
export const signUp = (formData) => API.post("/users/signup", formData);
export const forgot = (formData) => API.post("/users/forgot", formData);
export const reset = (formData) => API.post("/users/reset", formData);
export const fetchUsers = () => API.get("/users");
export const deleteUser = (id) => API.post(`/users/delete/${id}`);
export const updateUser = (id, updatedUser) =>
  API.post(`/users/${id}`, updatedUser);

export const fetchProfilesBySearch = (searchQuery) =>
  API.get(
    `/profiles/search?searchQuery=${
      searchQuery.search || searchQuery.year || "none"
    }`
  );
export const fetchProfile = (id) => API.get(`/profiles/${id}`);
export const fetchProfiles = () => API.get("/profiles");
export const fetchProfilesByUser = (searchQuery) =>
  API.get(`/profiles?searchQuery=${searchQuery.search}`);
// console.log("fetchProfilesByUser called in client")
// console.log(searchQuery.search)

export const createProfile = (newProfile) => API.post("/profiles", newProfile);
export const updateProfile = (id, updatedProfile) =>
  API.post(`/profiles/${id}`, updatedProfile);
export const deleteProfile = (id) => API.post(`/profiles/delete/${id}`);

export const fetchItem = (id) => API.get(`/items/${id}`);
export const fetchItems = () => API.get("/items");
export const addItem = (item) => API.post("/items", item);
export const addItems = (item) => API.post("/items/file", item);
export const updateItem = (id, updatedItem) =>
  API.post(`/items/update/${id}`, updatedItem);
export const deleteItem = (id) => API.post(`/items/delete/${id}`);
export const deleteItemsByCategoryId = (id) => API.post(`/items/deleteByCategoryId/${id}`);
export const fetchItemsByUser = (searchQuery) =>
  API.get(`/items/user?searchQuery=${searchQuery.searcategoriesch}`);
export const updateItems = async (updatedItems) => {
  try {
    console.log("Update items from API index: ", updatedItems);
    const response = await API.post(`/items/update`, updatedItems);

    if (response.status === 200) {
      console.log("Items updated successfully");
    } else {
      console.error("Failed to update items");
    }
  } catch (error) {
    console.error("An error occurred while updating items:", error);
  }
};

export const fetchUnits=()=>API.get(`/units`)
export const addUnit=(unit)=>API.post(`/units`, unit)
export const updateUnit=(id,unit)=>API.post(`/units/update/${id}`,unit)
export const addUnits=(unit)=>API.post(`/units/createAll`, unit)
export const getUnitById=(id)=>API.get(`/units/${id}`)
export const deleteUnit=(id)=>API.delete(`/units/delete/${id}`)
export const convertUnits=(unit)=>API.post(`/units/convert`,unit)







export const fetchCategory = (id) => API.get(`/categories/${id}`);
export const addCategory = (stock) => API.post("/categories", stock);
export const updateCategory = (id, updatedCategory) =>
  API.post(`/categories/${id}`, updatedCategory);
export const deleteCategory = (id) => API.post(`/categories/delete/${id}`);
export const fetchCategoriesByUser = (searchQuery) =>
  API.get(`/categories/user?searchQuery=${searchQuery.search}`);
export const fetchCategories = () => API.get("/categories");

export const fetchStock = (id) => API.get(`/stocks/${id}`);
export const addStock = (stock) => API.post("/stocks", stock);
export const updateStock = (id, updatedStock) =>
  API.post(`/stocks/${id}`, updatedStock);
export const deleteStock = (id) => API.post(`/stocks/delete/${id}`);
export const deleteStockById = (id, type) => API.post(`/stocks/${type}/${id}`);
export const fetchStocksByUser = (searchQuery) =>
  API.get(`/stocks/user?searchQuery=${searchQuery.search}`);
export const fetchStocks = () => API.get("/stocks");

export const getCurrentStockQuantity=(itemId)=>API.get(`/stocks/quantity/${itemId}`);




export const fetchStockAdjustment = (id) => API.get(`/stockAdjustments/${id}`);
export const addStockAdjustment = (stockAdjustment) =>
  API.post("/stockAdjustments", stockAdjustment);
export const updateStockAdjustment = (id, updatedStockAdjustment) =>
  API.post(`/stockAdjustments/${id}`, updatedStockAdjustment);
export const deleteStockAdjustment = (id) =>
  API.post(`/stockAdjustments/delete/${id}`);
export const fetchStockAdjustmentsByUser = (searchQuery) =>
  API.get(`/stockAdjustments?searchQuery=${searchQuery.search}`);
export const fetchStockAdjustments = () => API.get("/stockAdjustments");

export const fetchStaff = (id) => API.get(`/staff/${id}`);
export const fetchStaffByPage = (page) => API.get(`/staff?page=${page}`);
export const addStaff = (client) => API.post("/staff", client);
export const updateStaff = (id, updatedStaff) =>
  API.post(`/staff/${id}`, updatedStaff);
export const deleteStaff = (id) => API.post(`/staff/delete/${id}`);
export const fetchStaffByUser = (searchQuery) =>
  API.get(`/staff/user?searchQuery=${searchQuery.search}`);
export const fetchAllStaff = () => API.get("/staff");
