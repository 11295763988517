import React, { useState, useEffect, useContext } from "react";
import { getAllUnits } from "../../../actions/unitActions";
import { ItemContext } from "../../../Context/ItemContext";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Container,
  Box,
  Button,
  IconButton,
} from "@material-ui/core";
import NoData from "../../svgIcons/NoData";
import Spinner from "../../Spinner/Spinner";
import Units from "./Units";
import AddUnit from "./addUnit.js";
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const UnitsList = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  // const [unitId, setUnitId] = useState(null)
  const { unitId, setUnitId } = useContext(ItemContext);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  const units = useSelector((state) => state.units.units);
  
  const isLoading = useSelector((state) => state.units.isLoading); // Assuming you have stored items in the Redux store

  useEffect(() => {
    dispatch(getAllUnits());
    // dispatch(getItemsByUser({ search: user.result?._id || user.result.googleId }));
  }, [location, dispatch]);

  if (!user) {
    history.push("/login");
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (units.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
          margin: "80px",
        }}
      >
        <NoData />
        <p style={{ padding: "40px", color: "gray", textAlign: "center" }}>
          No items yet. Click the plus icon to add items
        </p>
      </div>
    );
  }
  return (
   <Container maxWidth="md" className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setUnitId(null);
          setOpen(true);
        }}
       // className={classes.addCategoryButton}
        startIcon={<AddIcon />}
      >
        Add Unit
      </Button>
      <AddUnit
        open={open}
        setOpen={setOpen}
        unitId={unitId}
        setUnitId={setUnitId}
        units={units}
      />
      <Units
        open={open}
        setOpen={setOpen}
        unitId={unitId}
        setUnitId={setUnitId}
        units={units}
      />
    </Container>
  );
};

export default UnitsList;
