import * as api from "../api/index";

import {
  ADD_NEW_STOCK,
  UPDATE_STOCK,
  DELETE_STOCK,
  DELETE_STOCKS,
  FETCH_STOCKS,
  FETCH_STOCK,
  START_LOADING,
  END_LOADING,
} from "./constants";

export const getAllStocks = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchStocks();
    console.log(data);
    dispatch({ type: FETCH_STOCKS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

// export const getStocksByUser = (searchQuery) => async (dispatch) => {
//   try {
//     dispatch({ type: START_LOADING });
//     const {
//       data: { data },
//     } = await api.fetchStocksByUser(searchQuery);
//     dispatch({ type: FETCH_Stock_BY_USER, payload: data });
//     dispatch({ type: END_LOADING });
//   } catch (error) {
//     console.log(error.response);
//   }
// };

export const getStock = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));

  try {
    const { data } = await api.fetchStock(id);
    // console.log("getStockdata",data)
    // const businessDetails = await api.fetchProfilesByUser(user.result._id);
    // // console.log("Business Details: " ,businessDetails)
    // const StockData = { ...data, businessDetails };

    // console.log("Stock Data",StockData)
    dispatch({ type: FETCH_STOCK, payload: data });
  } catch (error) {
    console.log(error.response);
  }
};

export const createStock = (stock, history) => async (dispatch) => {
  try {
    debugger
    console.log("Create Stock Called");
    dispatch({ type: START_LOADING });
    const  {data}  = await api.addStock(stock);
    console.log("Created Stock Data", data);
    dispatch({ type: ADD_NEW_STOCK, payload: data });
    
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const updateStock = (id, Stock) => async (dispatch) => {
  try {
    const { data } = await api.updateStock(id, Stock);
    dispatch({ type:  UPDATE_STOCK, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteStock = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteStock(id);

    dispatch({ type:  DELETE_STOCK, payload: id });
    openSnackbar("Stock deleted successfully");
  } catch (error) {
    console.log(error.response);
  }
};
export const deleteStockById = (id, type, openSnackbar) => async (dispatch) => {
  try {
    
    await api.deleteStockById(id, type);
    dispatch({ type: DELETE_STOCKS, payload: { id, type } });
    openSnackbar("Stocks deleted successfully");
  } catch (error) {
    console.log(error.response);
  }
};
