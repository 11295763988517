import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ItemContext } from "../../../Context/ItemContext";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUnit, updateUnit } from "../../../actions/unitActions";
import { useSnackbar } from "react-simple-snackbar";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#1976D2",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
  inputStyle: {
    width: "100%",
    fontFamily: "kruti-101",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = (props) => {
  const classes = useStyles();
  return <MuiDialogContent {...props} />;
};

const DialogActions = (props) => {
  const classes = useStyles();
  return <MuiDialogActions {...props} />;
};

const AddUnit = ({ setOpen, open, units }) => {
  const classes = useStyles();
  const location = useLocation();
  const [unitData, setUnitData] = useState({
    name: "",
    baseUnit: null,
    abbreviation: "",
    conversionFactor: "",
    createdAt: new Date(),
  });
  console.log(units);
  const { unitId, setUnitId } = useContext(ItemContext);
  const dispatch = useDispatch();
  const unit = useSelector((state) =>
    unitId ? state.units.units.find((i) => i._id === unitId) : null
  );
  console.log(unit);
  const [selectedBaseUnit, setSelectedBaseUnit] = useState(null);
  console.log("selectedBaseUnit",selectedBaseUnit);
  const [isEditMode, setIsEditMode] = useState(false); // New state variable
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const [errors, setErrors] = useState({});

  const handleSubmitItem = (e) => {
    e.preventDefault();

    if (validateForm()) {
      if (unitId) {
        dispatch(updateUnit(unitId, unitData, openSnackbar));
      } else {
        dispatch(createUnit(unitData, openSnackbar));
      }
      clear();
      handleClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUnitData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the specific field
    }));
  };
  const handleBaseUnitChange = (index, value) => {
    setUnitData({ ...unitData, baseUnit: index });
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (unitData.name.trim() === "") {
      newErrors.name = "Item name is required";
      isValid = false;
    }
    if (unitData.abbreviation.trim() === "") {
      newErrors.abbreviation = "Abbreviation is required";
      isValid = false;
    }
    // if (unitData.quantity.trim() === "") {
    //   newErrors.quantity = "Quantity is required";
    //   isValid = false;
    // }
    // if (unitData.weight.trim() === "") {
    //   newErrors.weight = "Weight is required";
    //   isValid = false;
    // }

    setErrors(newErrors);
    return isValid;
  };

  const clear = () => {
    setUnitData({
      name: "",
      baseUnit: null,
      abbreviation: "",
      conversionFactor: "",
    });
    
    setErrors({});
  };
  useEffect(() => {
    if (unit) {
      // When editing an unit, set the form data and switch to edit mode
      setUnitData(unit);

      const defaultUnit =
        units.find((ut) => ut._id === unit.baseUnit) || null;
      setSelectedBaseUnit(defaultUnit);

      setIsEditMode(true);
    } else {
      // When opening for a new unit, reset the form and switch to add mode
      clear();
      setIsEditMode(false);
    }
  }, [unit, open]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  // useEffect(() => {
  //   var checkId = user?.result?._id;
  //   if (checkId !== undefined) {
  //     setUnitData({ ...unitData, unitId: [checkId] });
  //   } else {
  //     setUnitData({ ...unitData, unitId: [user?.result?.googleId] });
  //   }
  // }, [location]);

  const handleClose = () => {
    setUnitData({
      name: "",
      baseUnit: null,
      abbreviation: "",
      conversionFactor: "",
    });
    setSelectedBaseUnit(null);
    setErrors({});
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            paddingLeft: "20px",
            color: "white",
            backgroundColor: "#ef7f1a",
          }}
        >
          {unitId ? "Edit Unit" : "New Unit"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                variant="outlined"
                className={classes.inputStyle}
                name="name"
                type="text"
                required
                onChange={handleChange}
                value={unitData.name}
                error={!!errors.name} // Check if error exists
                helperText={errors.name} // Display error message
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Abbreviation"
                variant="outlined"
                className={classes.inputStyle}
                name="abbreviation"
                required
                onChange={handleChange}
                value={unitData.abbreviation}
                error={!!errors.abbreviation} // Check if error exists
                helperText={errors.abbreviation} // Display error message
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <TextField
                label="BaseUnit"
                variant="outlined"
                className={classes.inputStyle}
                name="baseUnit"
                type="number"
                required
                onChange={handleChange}
                value={unitData.baseUnit}
                error={!!errors.baseUnit} // Check if error exists
                helperText={errors.baseUnit} // Display error message
              /> */}
              {/* {unitData.baseUnit} */}
              <div style={{ display: "block" }}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    options={units}
                    getOptionLabel={(unit) =>
                      `${unit.name} | ${unit.abbreviation}`
                    }
                    value={selectedBaseUnit} // Use object instead of ID
                    onChange={(e, newValue) => {
                      setSelectedBaseUnit(newValue);

                      handleBaseUnitChange(newValue ? newValue._id : null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Base Unit"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Conversion Factor"
                variant="outlined"
                className={classes.inputStyle}
                name="conversionFactor"
                type="number"
                required
                onChange={handleChange}
                value={unitData.conversionFactor}
                error={!!errors.conversionFactor} // Check if error exists
                helperText={errors.conversionFactor} // Display error message
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                label="Quantity"
                variant="outlined"
                className={classes.inputStyle}
                name="quantity"
                type="number"
                required
                onChange={handleChange}
                value={unitData.quantity}
                error={!!errors.quantity} // Check if error exists
                helperText={errors.quantity} // Display error message
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={6}>
              <TextField
                label="Type"
                variant="outlined"
                className={classes.inputStyle}
                name="type"
                type="text"
                required
                onChange={handleChange}
                value={unitData.type}
                error={!!errors.type} // Check if error exists
                helperText={errors.type} // Display error message
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitItem}
            variant="contained"
            style={{
              marginRight: "25px",
              backgroundColor: "#ef7f1a",
              color: "white",
            }}
          >
            Save Unit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddUnit;
