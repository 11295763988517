import React, { useState, useEffect, useContext } from "react";
import { getAllItems, getItemsByUser } from "../../actions/itemActions";
import { ItemContext } from "../../Context/ItemContext";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import NoData from "../svgIcons/NoData";
import Spinner from "../Spinner/Spinner";
import AddItem from "./addItem";
import Items from "./Items";
import Categories from "./Categories";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Container,
  Box,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { getAllUnits } from "../../actions/unitActions";
import PrintIcon from "@material-ui/icons/Print";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ItemsList = () => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { itemId, setItemId } = useContext(ItemContext);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  const { items } = useSelector((state) => state.items);
  const isLoading = useSelector((state) => state.items.isLoading);

  const [selectedOption, setSelectedOption] = useState("Items");
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllItems());
  }, [location, dispatch, selectedOption]);

  if (!user) {
    history.push("/login");
  }

  return (
    <Container maxWidth="md" className={classes.root}>
      <Box>
        <RadioGroup
          row
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <FormControlLabel
            value="Items"
            control={<Radio style={{ color: "#ef7f1a" }} />}
            label="Items"
          />
          <FormControlLabel
            value="Categories"
            control={<Radio style={{ color: "#ef7f1a" }} />}
            label="Categories"
          />
        </RadioGroup>
      </Box>

      {isLoading ? (
        <Spinner />
      ) : items.length === 0 && selectedOption === "Items" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingTop: "20px",
            margin: "80px",
          }}
        >
          <NoData />
          <p style={{ padding: "40px", color: "gray", textAlign: "center" }}>
            No items yet. Click the plus icon to add items
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setItemId(null);
              setOpen(true);
            }}
            className={classes.addCategoryButton}
          >
            Add Item
          </Button>
          <AddItem open={open} setOpen={setOpen} />
        </div>
      ) : (
        <>
          {selectedOption === "Items" && (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setItemId(null);
                  setOpen(true);
                }}
                className={classes.addCategoryButton}
                startIcon={<AddIcon />}
              >
                Add Item
              </Button>
              <AddItem
                open={open}
                setOpen={setOpen}
                itemId={itemId}
                setItemId={setItemId}
              />
              <Items
                open={open}
                setOpen={setOpen}
                itemId={itemId}
                setItemId={setItemId}
                items={items}
              />
            </div>
          )}

          {selectedOption === "Categories" && <Categories />}
        </>
      )}
    </Container>
  );
};

export default ItemsList;
