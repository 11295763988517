import React, { forwardRef } from "react";
import Barcode from "react-barcode";

const AllBarcodesPrint = forwardRef(({ items }, ref) => {
  
  const rows = [];
  const itemsPerRow = 4; // Number of columns
  const itemsPerPage = 8; // Number of rows

  for (let i = 0; i < itemsPerPage; i++) {
    const rowItems = [];
    for (let j = 0; j < itemsPerRow; j++) {
      const index = i * itemsPerRow + j;
      if (index < items.length) {
        const currentItem = items[index];
        rowItems.push(
          <div key={index} style={{ margin: "20px", float: "left" }}>
            <div>{currentItem.itemName}</div>
            <Barcode
              value={currentItem.barcode ? currentItem.barcode : currentItem._id}
              width={0.75}
              height={30}
              displayValue={true}
              fontSize={15}
            />
          </div>
        );
      }
    }
    rows.push(
      <div key={i} style={{ clear: "both", marginBottom: "20px" }}>
        {rowItems}
      </div>
    );
  }

  return <div ref={ref}>{rows}</div>;
});

export default AllBarcodesPrint;
