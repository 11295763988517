import React, { useState, useEffect } from "react";
// import "../../../node_modules/react-progress-button/react-progress-button.css"
import { useSnackbar } from "react-simple-snackbar";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../../initialState";
import { getPurchase } from "../../actions/purchaseActions";
import { getAllItems } from "../../actions/itemActions";
import { toCommas } from "../../utils/utils";
import styles from "./PurchaseDetails.module.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Container, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Spinner from "../Spinner/Spinner";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ProgressButton from "react-progress-button";
import axios from "axios";
import { saveAs } from "file-saver";
import Modal from "../Payments/Modal";
// import PaymentHistory from "./PaymentHistory";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  useMediaQuery,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const PurchaseDetails = () => {
  const location = useLocation();
  const [state, setState] = useState(0);
  const [purchaseData, setPurchaseData] = useState(initialState);
  const [rates, setRates] = useState(0);
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [client, setClient] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [signatureDataUrl, setSignatureDataUrl] = useState(null);
  const [type, setType] = React.useState("");
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState({});
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  console.log("id", id);
  const { purchase } = useSelector((state) => state.purchases);
  console.log(purchase);
  const items = useSelector((state) => state.items.items);

  const dispatch = useDispatch();
  const history = useHistory();
  const [sendStatus, setSendStatus] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [printStatus, setPrintStatus] = useState(null);
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const user = JSON.parse(localStorage.getItem("profile"));

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    table: {
      minWidth: 650,
    },

    headerContainer: {
      // display: 'flex'
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(1),
      backgroundColor: "#f2f2f2",
      borderRadius: "10px 10px 0px 0px",
    },
    wpContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    whatsappIcon: {
      height: '3rem',
      width: '3rem',
      cursor: 'pointer',
    },
    circularProgress: {
      marginLeft: '8px', // Add margin for mobile view
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    dispatch(getPurchase(id));
  }, [id, dispatch, location]);

  useEffect(() => {
    if (purchase) {
      //Automatically set the default purchase values as the ones in the purchase to be updated
      setPurchaseData(purchase);
      setRates(purchase.rates);
      setClient(purchase.client);
      setSupplier(purchase.supplier);
      setType(purchase.type);
      setStatus(purchase.status);
      setSelectedDate(purchase.dueDate);
      setVat(purchase.vat);
      setCurrency(purchase.currency);
      setSubTotal(purchase.subTotal);
      setTotal(purchase.total);
      setCompany(purchase?.businessDetails?.data);
      setSignatureDataUrl(purchase.signatureDataUrl);
    }
  }, [purchase]);
  useEffect(() => {
    dispatch(
      // getItemsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllItems()
    );
    // eslint-disable-next-line
  }, [dispatch]);

  //Get the total amount paid
  let totalAmountReceived = 0;
  for (var i = 0; i < purchase?.paymentRecords?.length; i++) {
    totalAmountReceived += Number(purchase?.paymentRecords[i]?.amountPaid);
  }

  const editPurchase = (id) => {
    history.push(`/edit/purchase/${id}`);
  };

  const createAndPrintPdf = () => {
    setPrintStatus("loading");
    axios
      .post(
        `${process.env.REACT_APP_API}/create-pdf/${purchase.purchaseNumber}`,
        {
          name: purchase.client.name,
          address: purchase.client.address,
          phone: purchase.client.phone,
          email: purchase.client.email,
          dueDate: purchase.client.dueDate,
          supplierName: purchase.supplier.name,
          supplierAddress: purchase.supplier.address,
          supplierPhone: purchase.supplier.phone,
          supplierEmail: purchase.supplier.email,
          signatureDataUrl: purchase.signatureDataUrl,
          date: purchase.createdAt,
          id: purchase.purchaseNumber,
          notes: purchase.notes,
          subTotal: toCommas(purchase.subTotal),
          total: toCommas(purchase.total),
          type: purchase.type,
          vat: purchase.vat,
          items: purchase.items,
          status: purchase.status,
          totalAmountReceived: toCommas(totalAmountReceived),
          balanceDue: toCommas(total - totalAmountReceived),
          company: company,
        }
      )
      .then(() =>
        axios.get(
          `${process.env.REACT_APP_API}/fetch-pdf/${purchase.purchaseNumber}`,
          {
            responseType: "blob",
          }
        )
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const downloadUrl = window.URL.createObjectURL(pdfBlob);
        setPrintStatus("success");

        // Open a new browser window for printing the PDF
        const printWindow = window.open(downloadUrl, "_blank");

        // Wait for the PDF to load, then trigger printing
        printWindow.onload = () => {
          printWindow.print();
          // You can close the print window automatically if needed
          // printWindow.close();
        };
      });
    // .then((res) => {
    //   const pdfBlob = new Blob([res.data], { type: "application/pdf" });
    //   // const downloadUrl = window.URL.createObjectURL(pdfBlob);
    //   // setDownloadStatus("success");
    //   // window.open(downloadUrl, "_blank");
    //   saveAs(pdfBlob, `order${purchase.purchaseNumber}.pdf`)
    //   const downloadUrl= `http://orders.sadhanaayurvedics.com/files/Exported-order/order${purchase.purchaseNumber}.pdf`
    //   window.open(downloadUrl,'_blank')
    //   setDownloadStatus("success");
    // });
  };
  const createAndDownloadPdf = () => {
    setDownloadStatus("loading");
    axios
      .post(
        `${process.env.REACT_APP_API}/create-purchase-pdf/${purchase.purchaseNumber}`,
        {
          name: purchase.client.name,
          address: purchase.client.address,
          phone: purchase.client.phone,
          email: purchase.client.email,
          dueDate: purchase.client.dueDate,
          supplierName: purchase.supplier.name,
          supplierAddress: purchase.supplier.address,
          supplierPhone: purchase.supplier.phone,
          supplierEmail: purchase.supplier.email,
          signatureDataUrl: purchase.signatureDataUrl,
          date: purchase.createdAt,
          id: purchase.purchaseNumber,
          notes: purchase.notes,
          subTotal: toCommas(purchase.subTotal),
          total: toCommas(purchase.total),
          type: purchase.type,
          vat: purchase.vat,
          items: purchase.items,
          status: purchase.status,
          totalAmountReceived: toCommas(totalAmountReceived),
          balanceDue: toCommas(total - totalAmountReceived),
          company: company,
        }
      )
      .then(() =>
        axios.get(
          `${process.env.REACT_APP_API}/fetch-purchase-pdf/${purchase.purchaseNumber}`,
          {
            responseType: "blob",
          }
        )
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // const downloadUrl = window.URL.createObjectURL(pdfBlob);
        // setDownloadStatus("success");
        // window.open(downloadUrl, "_blank");
        saveAs(pdfBlob, `order${purchase.purchaseNumber}.pdf`);
        const downloadUrl = `https://posstore.mindtree.co.th/files/Exported-purchase-order/purchase-order${purchase.purchaseNumber}.pdf`;
        window.open(downloadUrl, "_blank");
        setDownloadStatus("success");
      });
  };
  // const downloadPdf = (fileName) => {
  //   const apiUrl = `${process.env.REACT_APP_API}/fetch-pdf/${fileName}`;
  //   axios
  //     .get(apiUrl, { responseType: "blob" })
  //     .then((response) => {
  //       const file = new Blob([response.data], { type: "application/pdf" });
  //       saveAs(file, fileName);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading PDF:", error);
  //     });
  // };

  //SEND PDF Purchase VIA EMAIL
  const sendPdf = (e) => {
    e.preventDefault();
    setSendStatus("loading");
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API}/send-purchase-pdf-whatsapp`, {
        name: purchase.client.name,
        address: purchase.client.address,
        phone: purchase.client.phone,
        email: purchase.client.email,
        dueDate: purchase.client.dueDate,
        supplierName: purchase.supplier.name,
        supplierAddress: purchase.supplier.address,
        supplierPhone: purchase.supplier.phone,
        supplierEmail: purchase.supplier.email,
        signatureDataUrl: purchase.signatureDataUrl,
        date: purchase.createdAt,
        id: purchase.purchaseNumber,
        notes: purchase.notes,
        subTotal: toCommas(purchase.subTotal),
        total: toCommas(purchase.total),
        type: purchase.type,
        vat: purchase.vat,
        items: purchase.items,
        status: purchase.status,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        link: `${process.env.REACT_APP_URL}/purchase/${purchase._id}`,
        company: company,
      })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // console.log(resres.data)
        const downloadUrl = window.URL.createObjectURL(pdfBlob);
        setSendStatus("success");
        const encodedFilePath = encodeURIComponent(downloadUrl);
        console.log("Client Phone Number", purchase.client.phone);
        const whatsappURL = `https://wa.me/${purchase.supplier.phone}?text=https://posstore.mindtree.co.th/files/Exported-purchase-order/order${purchase.purchaseNumber}.pdf`;

        window.open(whatsappURL, "_blank");

        // window.open(res.data.whatsappURL, "_blank");
      })
      // .then(() => console.log("purchase sent successfully"))
      .then(() => {
        setSendStatus("success");
        setLoading(false);
        history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error);
        setSendStatus("error");
      });
  };

  const iconSize = {
    height: "18px",
    width: "18px",
    marginRight: "10px",
    color: "gray",
  };
  const [open, setOpen] = useState(false);

  function checkStatus() {
    return totalAmountReceived >= total
      ? "green"
      : status === "Partial"
      ? "#1976d2"
      : status === "Paid"
      ? "green"
      : status === "Unpaid"
      ? "red"
      : "red";
  }

  if (!purchase) {
    return <Spinner />;
  }
  // const findItemNameById = (itemId) => {
  //   console.log("parameter"+itemId);
  //   const item = items.find((item) => item._id ==itemId);
  //   console.log(user)
  //   return item ? item.name : "";
  // };
  const colorTheme = createTheme({
    palette: {
      primary: {
        main: "#ef7f1a",
      },
    },
  });
  return (
    <div className={styles.PageLayout}>
      <div
        className={styles.buttons}
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: "10px",
        }}
      >
        <div style={{ width: isSmallScreen ? "90%" : "auto" }}>
          {user?.result?.userType === "admin" && (
            <button
              className={styles.btn}
              onClick={() => editPurchase(purchaseData._id)}
            >
              <BorderColorIcon style={iconSize} />
              Edit Order
            </button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginRight: "10px",
          }}
        >
          <ProgressButton
            onClick={createAndDownloadPdf}
            state={downloadStatus}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "Transparent",
              color: "#000000",
              border: "none",
              borderRadius: "24px",
              padding: "8px",
              height: "40px",
              width: isSmallScreen ? "100%" : "10rem",
              marginTop: "7px",
              cursor: "pointer",
            }}
          >
            Download
          </ProgressButton>
          <ProgressButton
            onClick={createAndPrintPdf}
            state={printStatus}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "Transparent",
              color: "#000000",
              border: "none",
              borderRadius: "24px",
              padding: "8px",
              height: "40px",
              width: isSmallScreen ? "100%" : "10rem",
              marginTop: "7px",
              cursor: "pointer",
            }}
          >
            Print
          </ProgressButton>
          {/* <Button onClick={()=>IminPrint()}>
              IMIN Print
            </Button> */}
          <ThemeProvider theme={colorTheme}>
            <div className={classes.wpContainer}>
              {loading ? (
                <CircularProgress
                  className={isSmallScreen ? classes.circularProgress : ""}
                />
              ) : (
                <img
                  className={classes.whatsappIcon}
                  src="https://www.sharethis.com/wp-content/uploads/2017/05/WhatsApp.png"
                  alt=""
                  onClick={sendPdf}
                />
              )}
            </div>
          </ThemeProvider>
        </div>
      </div>

     

      <Modal open={open} setOpen={setOpen} purchase={purchase} />
      <div className={styles.purchaseLayout}>
        <Container className={classes.headerContainer}>
          <Grid
            container
            justifyContent="space-between"
            style={{ padding: "30px 0px" }}
          >
            {!purchase?.creator?.includes(
              user?.result._id || user?.result?.googleId
            ) ? (
              <Grid item></Grid>
            ) : (
              <Grid
                item
                onClick={() => history.push("/settings")}
                style={{ cursor: "pointer" }}
              >
                {company?.logo ? (
                  <img src={company?.logo} alt="Logo" className={styles.logo} />
                ) : (
                  <h2>{company?.name}</h2>
                )}
              </Grid>
            )}
            <Grid item style={{ marginRight: 40, textAlign: "right" }}>
              <Typography
                style={{
                  lineSpacing: 1,
                  fontSize: 45,
                  fontWeight: 700,
                  color: "gray",
                }}
              >
                {Number(total - totalAmountReceived) <= 0 ? "Receipt" : "Purchase Order"}
              </Typography>
              <Typography variant="overline" style={{ color: "gray" }}>
                No:{" "}
              </Typography>
              <Typography variant="body2">
                {purchaseData?.purchaseNumber}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Container>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "40px", marginBottom: "10px" }}
          >
            <Grid item>
              {/* {purchase?.creator?.includes(user?.result._id) && (
                      <Container style={{marginBottom: '20px'}}>
                        <Typography variant="overline" style={{color: 'gray'}} gutterBottom>From</Typography>
                        <Typography variant="subtitle2">{purchase?.businessDetails?.data[0]?.businessName}</Typography>
                        <Typography variant="body2">{purchase?.businessDetails?.data?.data?.email}</Typography>
                        <Typography variant="body2">{purchase?.businessDetails?.data?.data?.phoneNumber}</Typography>
                        <Typography variant="body2" gutterBottom>{purchase?.businessDetails?.data?.data?.address}</Typography>
                      </Container>
                    )} */}

              <Container>
              <Typography
                variant="overline"
                style={{ color: "gray", paddingRight: "3px" }}
                gutterBottom
              >
                Supply from
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {supplier.name}
              </Typography>
              {/* <Typography variant="body2">{supplier?._id}</Typography> */}
              {/* <Typography variant="body2">{supplier?.email}</Typography> */}
              <Typography variant="body1">{supplier?.phone}</Typography>
              {/* <Typography variant="body2">{supplier?.address}</Typography> */}
              </Container>
            </Grid>

            <Grid
              item
              style={{ marginRight: 20, textAlign: "left", marginLeft: 15 }}
            >
              {/* <Typography variant="overline" style={{color: 'gray'}} gutterBottom>Status</Typography> */}
              {/* <Typography variant="h6" gutterBottom style={{color: checkStatus()}}>{totalAmountReceived >= total ? 'Paid':status}</Typography> */}
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Date
              </Typography>
              <Typography variant="body2" gutterBottom>
                {purchase
                  ? moment(purchase.createdAt).format("MMM Do YYYY")
                  : moment().format("MMM Do YYYY")}
              </Typography>
              {/* <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Payment Term
              </Typography>
              <Typography variant="body2" gutterBottom>
                {client.dueDate} days
              </Typography> */}
              {/* <Typography variant="overline" gutterBottom>
                Amount
              </Typography>
              <Typography variant="h6" gutterBottom>
                {currency} {toCommas(total)}
              </Typography> */}
            </Grid>
            {/* <Container>
              <Typography
                variant="overline"
                style={{ color: "gray", paddingRight: "3px" }}
                gutterBottom
              >
                Supply from
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {supplier.name}
              </Typography>
              <Typography variant="body1">{supplier?.phone}</Typography>
              <Typography variant="body2">{supplier?.address}</Typography>
            </Container> */}
          </Grid>
        </Container>

        <form>
          <div>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "18px" }}>Item</TableCell>
                    <TableCell style={{ fontSize: "18px" }}>Unit</TableCell>
                    <TableCell style={{ fontSize: "18px" }}>Qty</TableCell>
                    <TableCell style={{ fontSize: "18px" }}>MRP</TableCell>
                    {/* <TableCell>Disc(%)</TableCell> */}
                    <TableCell style={{ fontSize: "18px" }}>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseData?.items?.map((itemField, index) => (
                    
                    <TableRow key={index}>
                      <TableCell scope="row" style={{ width: "40%" }}>
                        {" "}
                        <InputBase
                          style={{ width: "100%" }}
                          outline="none"
                          sx={{ ml: 1, flex: 1 }}
                          type="text"
                          name="itemName"
                          value={itemField?.item?.itemName}
                          placeholder="Item name or description"
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          name="unit"
                          
                          value={itemField?.item?.units?.purchase?.unit?.name}
                          placeholder="0"
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="quantity"
                          value={itemField?.quantity}
                          placeholder="0"
                          readOnly
                        />{" "}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="unitPrice"
                          value={itemField?.item?.units?.purchase?.cost}
                          placeholder="0"
                          readOnly
                        />{" "}
                      </TableCell>
                      {/* <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          type="number"
                          name="discount"
                          value={itemField?.discount}
                          readOnly
                        />{" "}
                      </TableCell> */}
                      <TableCell align="right">
                        {" "}
                        <InputBase
                          sx={{ ml: 1, flex: 1, textAlign: "right" }}
                          type="number"
                          name="amount"
                          value={itemField?.quantity * itemField?.item?.units?.purchase?.cost}
                          readOnly
                        />{" "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className={styles.addButton}></div>
          </div>

          <div className={styles.purchaseSummary}>
            {/* <div className={styles.summary}>Order Summary</div>
            <div className={styles.summaryItem}>
              <p>Total Items:</p>
              <h4>{purchaseData.items.length}</h4>
            </div> */}
            {/* <div className={styles.summaryItem}>
                        <p>{`Discount(${rates}%):`}</p>
                        <h4>{vat}</h4>
                    </div> */}
            {/* <div className={styles.summaryItem}>
                        <p>Total</p>
                        <h4>{currency} {toCommas(total)}</h4>
                    </div> */}
            {/* <div className={styles.summaryItem}>
                        <p>Paid</p>
                        <h4>{currency} {toCommas(totalAmountReceived)}</h4>
                    </div> */}

            <div className={styles.summaryItem}>
              <h3>Total [MRP Value]</h3>
              <h4
                style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}
              >
                {currency} {toCommas(total - totalAmountReceived)}
              </h4>
            </div>
          </div>

          <div className={styles.note}>
            <h4 style={{ marginLeft: "-10px" }}>Notes/Payment Info</h4>
            <p style={{ fontSize: "14px" }}>{purchaseData.notes}</p>
          </div>
          <div className={styles.signImg}>
            {signatureDataUrl && (
              <>
                <h4 style={{ marginLeft: "-10px" }}>Signature</h4>
                <img
                  src={signatureDataUrl}
                  alt="signature"
                  className="signature"
                  width={100}
                  height={50}
                />
              </>
            )}
          </div>
          {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        </form>
      </div>
    </div>
  );
};

export default PurchaseDetails;
