import * as api from '../api/index'

import { ADD_NEW_MEETING, UPDATE_MEETING, DELETE_MEETING, GET_MEETING, FETCH_MEETING_BY_USER, FETCH_ALL_MEETINGS,START_LOADING, END_LOADING } from './constants'

export const getMeetings = () => async (dispatch)=> {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.fetchMeetings()
        // console.log(data)
        dispatch({ type: FETCH_ALL_MEETINGS, payload: data })
        dispatch({ type: END_LOADING })
    } catch (error) {
        console.log(error)
    }
}

export const getMeetingsByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchMeetingsByUser(searchQuery)
      dispatch({ type: FETCH_MEETING_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const getMeeting = (id) => async (dispatch)=> {

    const user = JSON.parse(localStorage.getItem('profile'))

    try {
        const { data } = await api.fetchMeeting(id)
        const businessDetails = await api.fetchProfilesByUser({search: user?.result?._id || user?.result?.googleId})
        const meetingData = {...data, businessDetails}
        // console.log(meetingData)
        dispatch({ type: GET_MEETING, payload: meetingData  })
    } catch (error) {
        console.log(error.response)
    }
}

export const createMeeting =(meeting, history) => async (dispatch) => {

    try {
        console.log(meeting)
        dispatch({ type: START_LOADING })
        const { data } = await api.addMeeting(meeting)
       
        dispatch({ type: ADD_NEW_MEETING, payload: data })
        // history.push(`/meetings/${data._id}`)
        dispatch({ type: END_LOADING })
    } catch (error) {
        console.log(error)
    }
}

export const updateMeeting =(id, meeting) => async (dispatch) => {

    try {
        const { data } = await api.updateMeeting(id, meeting)
        dispatch({ type: UPDATE_MEETING, payload: data })
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteMeeting =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteMeeting(id)

        dispatch({type: DELETE_MEETING, payload: id})
        openSnackbar("Daily Report deleted successfully")
    } catch (error) {
        console.log(error.response)
    }
}