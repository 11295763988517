import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory, useLocation, useRouteMatch, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";
import styles from "./Header.module.css";
import IconButton from "@material-ui/core/IconButton";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { Typography, Drawer } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import NavBar from "../NavBar/NavBar";

// import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(2),
  },
  heading: {
    marginLeft: "auto", // Add this property to shift the heading to the right
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 200,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 200,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    height: "9%",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "inherit",
  },
  listItemIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Header = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const history = useHistory();
  const location = useLocation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [headingText, setHeadingText] = useState("Dashboard");

  const match = useRouteMatch([
    "/invoices",
    "/invoices/credit",
    "/purchases",
    "/meetings",
    "/settings",
    "/dashboard",
    "/customers",
    "/itemsList",
    "/adminDashboard",
    "/invoice",
    "/suppliers",
    "/stockAdjustments",
    "/stockDetails",
    "/userStats/:userId",
    "/Webview/PrivacyPolicy",
    "/stats/:invoiceId",
    "/staff"
  ]);
  useEffect(() => {
    let newHeadingText = "";
    if (match && match.path === "/invoices/credit") {
      newHeadingText = "Credit Invoices";
    } 
    else if (match && match.path === "/invoices") {
      newHeadingText = "Invoices";
    }else if (match && match.path === "/purchases") {
      newHeadingText = "Purchases";
    } else if (match && match.path === "/stockAdjustments") {
      newHeadingText = "Stock Adjustment";
    } else if (match && match.path === "/stockDetails") {
      newHeadingText = "Stock Summary";
    }else if (match && match.path === "/settings") {
      newHeadingText = "Profile Settings";
    } else if (match && match.path === "/dashboard") {
      newHeadingText = "Dashboard";
    } else if (match && match.path === "/customers") {
      newHeadingText = "Parties";
    }else if (match && match.path === "/staff") {
      newHeadingText = "Staff";
    }
     else if (match && match.path === "/itemsList") {
      newHeadingText = "Items";
    } else if (match && match.path === "/adminDashboard") {
      newHeadingText = "Sales Person";
    } else if (match && match.path === "/invoice") {
      newHeadingText = "Create Invoice";
    } else if (match && match.path === "/suppliers") {
      newHeadingText = "Suppliers";
    } else if (match && match.path === "/userStats/:userId") {
      newHeadingText = "Sales Person Stats";
    } else if (match && match.path === "/stats/:invoiceId") {
      newHeadingText = "Party Stats";
    } else if (match && match.path === "/Webview/PrivacyPolicy") {
      newHeadingText = "Privacy Policy";
    }
    //("newHeadingText", newHeadingText);
    setHeadingText(newHeadingText);
  }, [match]);
  // if (match && match.path === "/invoices") {
  //   headingText = "Invoices";
  // } else if (match && match.path === "/meetings") {
  //   headingText = "Meetings";
  // } else if (match && match.path === "/settings") {
  //   headingText = "Settings";
  // } else if (match && match.path === "/Dashboard") {
  //   headingText = "Dashboard";
  // } else if (match && match.path === "/customers") {
  //   headingText = "Customers";
  // } else if (match && match.path === "/ItemsList") {
  //   headingText = "Items";
  // } else if (match && match.path === "/adminDashboard") {
  //   headingText = "Users";
  // } else if (match && match.path === "/invoice") {
  //   headingText = "Add Order";
  // } // Add more conditions for other pages if needed
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  //GET REPO INFO FROM GITHUB
  // useEffect(() => {
  //   getMetaData()
  // },[])

  // const getMetaData = async() => {
  //   const response = await axios.get('https://api.github.com/repos/panshak/arc')
  //       // //(response.data);
  // }

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    history.push("/");
    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;
    // setUser(JSON.parse(localStorage.getItem('profile')))
    //If token expires, logout the user
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
    // eslint-disable-next-line
  }, [location, user]); //when location changes, set the user

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const openLink = (link) => {
    history.push(`/${link}`);
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  if (!user)
    return (
      <div className={styles.header2}>
        {/* <img   onClick={()=> history.push('/')} src="https://sadhanaayurvedics.com/wp-content/uploads/2021/12/5.png" alt="Sadhana Ayurvedics" /> */}
      </div>
    );
  return (
    <div className={styles.header}>
      {isSmallScreen && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
      )}
    
        <IconButton aria-label="home"  onClick={() => (window.location.href = "/PosMainScreen")}>
          <HomeIcon />
        </IconButton>
     

      <Typography
        variant="h4"
        className={`${styles.heading} ${classes.heading}`}
        style={{ whiteSpace: "nowrap" }}
      >
        {headingText}
      </Typography>

      {isSmallScreen && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={openDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <img
              style={{ width: "100px" }}
              src="https://sadhanaayurvedics.com/wp-content/uploads/2021/12/5.png"
              alt="arc-invoice"
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button onClick={handleDrawerClose}>
              <Link to="/dashboard" className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-pie-chart"
                  >
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                    <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={handleDrawerClose}>
              <Link to="/invoices" className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                    <polyline points="2 17 12 22 22 17"></polyline>
                    <polyline points="2 12 12 17 22 12"></polyline>
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Orders" />
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={handleDrawerClose}>
              <Link to="/meetings" className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-layers"
                  >
                    <path d="M 7 2 C 5.3550302 2 4 3.3550302 4 5 L 4 19 C 4 20.64497 5.3550302 22 7 22 L 20 22 L 20 20 L 7 20 C 6.4349698 20 6 19.56503 6 19 C 6 18.43497 6.4349698 18 7 18 L 20 18 L 20 16 L 20 2 L 16 2 L 10 2 L 7 2 z M 7 4 L 10 4 L 10 12.123047 L 11.574219 11.019531 L 13 10.017578 L 16 12.123047 L 16 4 L 18 4 L 18 16 L 7 16 C 6.6481575 16 6.3148915 16.07394 6 16.1875 L 6 5 C 6 4.4349698 6.4349698 4 7 4 z M 12 4 L 14 4 L 14 8.2773438 L 13 7.5761719 L 12 8.2773438 L 12 4 z" />
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Daily Report" />
              </Link>
            </ListItem>
          </List>
          {user?.result?.userType === "admin" && (
            <>
              <Divider />
              <List>
                <ListItem button onClick={handleDrawerClose}>
                  <Link to="/adminDashboard" className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sales Person" />
                  </Link>
                </ListItem>
              </List>
            </>
          )}
          <Divider />
          <List>
            <ListItem button onClick={handleDrawerClose}>
              <Link to="/customers" className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-users"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </ListItemIcon>
                <ListItemText primary="Parties" />
              </Link>
            </ListItem>
          </List>
          {user?.result?.userType === "admin" && (
            <>
              <Divider />
              <List>
                <ListItem button onClick={handleDrawerClose}>
                  <Link to="/suppliers" className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Suppliers" />
                  </Link>
                </ListItem>
              </List>
            </>
          )}
          {user?.result?.userType === "admin" && (
            <>
              <Divider />
              <List>
                <ListItem button onClick={handleDrawerClose}>
                  <Link to="/ItemsList" className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="24px"
                        height="24px"
                        stroke="currentColor"
                      >
                        <path d="M 1 3 L 1 15 L 3 15 L 3 48 L 47 48 L 47 15 L 49 15 L 49 3 Z M 3 5 L 47 5 L 47 13 L 3 13 Z M 5 15 L 45 15 L 45 46 L 5 46 Z M 17.5 19 C 15.578125 19 14 20.578125 14 22.5 C 14 24.421875 15.578125 26 17.5 26 L 32.5 26 C 34.421875 26 36 24.421875 36 22.5 C 36 20.578125 34.421875 19 32.5 19 Z M 17.5 21 L 32.5 21 C 33.339844 21 34 21.660156 34 22.5 C 34 23.339844 33.339844 24 32.5 24 L 17.5 24 C 16.660156 24 16 23.339844 16 22.5 C 16 21.660156 16.660156 21 17.5 21 Z" />
                      </svg>
                    </ListItemIcon>
                    <ListItemText primary="Items" />
                  </Link>
                </ListItem>
              </List>
            </>
          )}
          <Divider />
          <List>
            <ListItem button onClick={handleDrawerClose}>
              <Link to="/settings" className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-settings"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  style={{ fontWeight: "bold" }}
                />
              </Link>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem>
              <div className={styles.rightSection}>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Avatar
                      style={{ backgroundColor: "#ef7f1a", color: "black" }}
                    >
                      {user?.result?.name?.charAt(0)}
                    </Avatar>
                    <Typography variant="h6">
                      {(user?.result?.name).split(" ")[0]}
                    </Typography>
                  </div>
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper elevation={3}>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {/* <MenuItem onClick={() => openLink("settings")}>
                              {(user?.result?.name).split(" ")[0]}
                            </MenuItem> */}
                            <MenuItem onClick={() => logout()}>Logout</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </ListItem>
          </List>
        </Drawer>
      )}
      {!isSmallScreen && (
        <div className={styles.rightSection}>
          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Avatar
              style={{
                backgroundColor: "white",
                color: "#ef7f1a",
                border: "1px solid",
              }}
            >
              {user?.result?.name?.charAt(0)}
            </Avatar>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper elevation={3}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={() => openLink("settings")}>
                        {(user?.result?.name).split(" ")[0]}
                      </MenuItem>
                      <MenuItem onClick={() => openLink("help")}>
                        {("Help").split(" ")[0]}
                      </MenuItem>
                      <MenuItem onClick={() => logout()}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}
    </div>
  );
};

export default Header;
