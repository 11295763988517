import React from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { useCart } from "../Context/CartContext";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import TouchRipple from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "./CartStyles";


const options = {
    position: "bottom",
    style: {
      backgroundColor: "red",
      border: "2px solid red",
      color: "white",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  };
  

const CartTable = ({currency}) => {
  const classes = useStyles();
  const [openSnackbar, closeSnackbar] = useSnackbar(options);
  const {
    cartItems,
    selectedIndex,
    addToCart,
    removeFromCart,
    incrementQuantity,
    decrementQuantity,
    getTotalPrice,
    getSubTotalPrice,
    calculatePrice,
    handleOpenQtyDialog,
    handleCloseQtyDialog,
    QtydialogOpen,
    handleQuantityChange,
    calculateVat,
    subTotal,
    setSubTotal,
    setCartItems,
    vat,
    setVat,
  } = useCart();
  return (
    <div className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableHead}>Item Name</TableCell>
            <TableCell className={classes.tableHead}>Qty</TableCell>
            <TableCell className={classes.tableHead}>Price</TableCell>
            <TableCell className={classes.tableHead}>Amount</TableCell>
            <TableCell className={classes.tableHead}>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {cartItems.map((item, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell}>
                {item.itemName}
              </TableCell>

              <TableCell
                className={`${classes.tableCell} ${classes.quantityText}`}
                style={{
                  cursor: "pointer",
                  position: "relative",
                  overflow: "hidden",
                }}
                onClick={() => {
                  handleOpenQtyDialog(index);
                  console.log("Selected index: " + selectedIndex);
                }}
              >
                <TouchRipple center="true" />
                {item.quantity}
                <TouchRipple />
              </TableCell>
              <TableCell className={classes.tableCell}>
                {`${currency}${item?.units?.sale?.price}`}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {`${currency}${calculatePrice(item.quantity, item?.units?.sale?.price)}`}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <IconButton
                  className={classes.deleteIcon}
                  onClick={() => removeFromCart(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        <Dialog
          open={QtydialogOpen}
          onClose={handleCloseQtyDialog}
          classes={{ paper: classes.dialog }}
        >
          <DialogTitle className={classes.dialogTitle}>
            Adjust Quantity
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {cartItems.length > 0 &&
            selectedIndex >= 0 &&
            selectedIndex < cartItems.length ? (
              <div className={classes.pillContainer}>
                <Typography variant="h6" className={classes.itemText}>
                  {cartItems[selectedIndex].name || ""}
                </Typography>
                <div className={classes.pillButtonGroup}>
                  <IconButton
                    className={classes.pillIconButton}
                    onClick={() => decrementQuantity(selectedIndex)}
                    disabled={cartItems[selectedIndex].quantity == 1}
                  >
                    <RemoveIcon style={{ width: "2em", height: "2em" }} />
                  </IconButton>
                  <TextField
                    className={classes.quantityInput}
                    type="number"
                    value={cartItems[selectedIndex].quantity}
                    onChange={(event) =>
                      handleQuantityChange(event, selectedIndex, openSnackbar)
                    }
                    variant="outlined"
                  />

                  <IconButton
                    className={classes.pillIconButton}
                    onClick={() =>
                      incrementQuantity(selectedIndex, openSnackbar)
                    }
                    disabled={
                      cartItems[selectedIndex].toggleStock &&
                      cartItems[selectedIndex].quantity >=
                        cartItems[selectedIndex].stockQty.currentQty
                    }
                  >
                    <AddIcon style={{ width: "2em", height: "2em" }} />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div>No item selected</div>
            )}
          </DialogContent>
        </Dialog>
      </Table>
    </div>
  );
};

export default CartTable;
