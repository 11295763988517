import React, { createContext, useState } from 'react';

export const EditContext = createContext();

export const EditProvider = ({ children }) => {
    const [currId,setCurrId]=useState(null);
    const [customerId,setCustomerId]=useState(null);
  
  return (
    <EditContext.Provider value={{currId,setCurrId,customerId,setCustomerId }}>
      {children}
    </EditContext.Provider>
  );
};
